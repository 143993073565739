import React from 'react';
import { Brick } from 'components/bricks/types/brick.type';
import AssetWarningMessage from 'components/bricks/components/shared/components/asset-warning-message';

interface Props {
    brick: Brick;
}

const widget = ({ brick }: Props) => {
    return (
        <div className={'asset-widget'}>
            <AssetWarningMessage brick={brick} />
        </div>
    );
};

export default widget;
