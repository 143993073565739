import briefing from 'components/bricks/components/shared/components/briefing-content/components';
import output from 'components/bricks/components/shared/components/output-tab';
import settings from './settings';
import setup from './setup';
import icon from './icon';
import metadata from './metadata';
import preview from './preview';
import publish from './publish';
import defaultData from './default-data';
import widget from './widget';

export default { settings, setup, icon, metadata, preview, publish, defaultData, widget, briefing, output };
