import { ValidationObject } from 'components/bricks/types/validator.type';

const validators: ValidationObject = {
    validators: [
        {
            condition: '!brick.data?.settings?.advertiserId',
            message: 'Advertiser id is required for CM360 Campaign',
            inputIdentifiers: ['cm360_campaign-advertiser_id'],
            tabKey: 'settings',
            severity: 'error'
        },
        {
            condition:
                'brick.data?.settings?.defaultLandingPageOption === "SELECT" && (!brick.data?.settings?.defaultLandingPageId || !brick.data?.settings?.defaultLandingPageId.length)',
            message: 'You need to select landing page for the campaign',
            inputIdentifiers: ['cm360_campaign-default_landing_page_id'],
            tabKey: 'settings',
            severity: 'error'
        },
        {
            condition:
                'brick.data?.settings?.defaultLandingPageOption === "CREATE" && (!brick.data?.settings?.defaultLandingPageName || !brick.data?.settings?.defaultLandingPageName.length)',
            message: 'You need to give a name of the landing page you want to create',
            inputIdentifiers: ['cm360_campaign-default_landing_page_name'],
            tabKey: 'settings',
            severity: 'error'
        },
        {
            condition:
                'brick.data?.settings?.defaultLandingPageOption === "CREATE" && (!brick.data?.settings?.defaultLandingPageUrl || !brick.data?.settings?.defaultLandingPageUrl.length)',
            message: 'You need to give a url of the landing page you want to create',
            inputIdentifiers: ['cm360_campaign-default_landing_page_url'],
            tabKey: 'settings',
            severity: 'error'
        },
        {
            condition:
                'brick.data?.settings?.defaultLandingPageOption === "CREATE" && brick.data?.settings?.defaultLandingPageUrl && !brick.data?.settings?.defaultLandingPageUrl.match(/\\b(?:https?:\\/\\/)?(?:www\\.)\\S+\\.\\S+\\b/gm)',
            message: 'The url you provided for the landing page is not valid',
            inputIdentifiers: ['cm360_campaign-default_landing_page_url'],
            tabKey: 'settings',
            severity: 'error'
        },
        {
            condition: '!brick.data?.settings?.startDate',
            message: 'Start date of the campaign is required',
            inputIdentifiers: ['cm360_campaign-start_time'],
            tabKey: 'settings',
            severity: 'error'
        },
        {
            condition: '!brick.data?.settings?.endDate',
            message: 'End date of the campaign is required',
            inputIdentifiers: ['cm360_campaign-end_time'],
            tabKey: 'settings',
            severity: 'error'
        }
    ]
};

export default validators;
