import { IEditorBlock } from 'components/bricks/types/editorBlock.type';
import { MODEL_SETTINGS } from 'components/bricks/constants';

const settings: IEditorBlock[] = [
    {
        title: 'General',
        itemType: 'block',
        type: 'multiInput',
        opened: true,
        items: [
            {
                type: 'text',
                title: 'Block title',
                identifier: 'cm360_placementGroup-title',
                model: 'title',
                itemType: 'input',
                label: 'Title',
                maxLength: 150,
                fixedModel: true,
                useValueObject: false
            },
            {
                type: 'dynamicData',
                title: 'Child title',
                identifier: 'cm360_placementGroup-child_title',
                condition: `additionalVars.brick.type==='subItem' && !additionalVars.multiSocial_campaign`,
                model: 'childTitle',
                itemType: 'input',
                label: 'Child title',
                maxLength: 150,
                useValueObject: false
            },
            {
                type: 'dynamicData',
                title: 'Pointer',
                identifier: 'cm360_placementGroup-pointer',
                condition: `additionalVars.brick.type==="subItem" && !additionalVars.multiSocial_campaign`,
                model: 'pointer',
                itemType: 'input',
                label: 'Pointer',
                maxLength: 150,
                useValueObject: false
            }
        ]
    },
    {
        title: 'Details',
        itemType: 'block',
        type: 'multiInput',
        opened: true,
        items: [
            {
                type: 'metadataInput',
                model: 'advertiserId',
                label: 'Advertiser id',
                identifier: 'cm360_placementGroup-advertiser_id',
                metadataType: 'cm360advertisers',
                useValueObject: false
            },
            {
                type: 'buttonGroup',
                model: 'siteType',
                identifier: 'cm360_placementGroup-site_type',
                label: 'Site type',
                tooltip: 'Site type',
                options: {
                    site: 'site',
                    directorySite: 'directory site'
                }
            },
            {
                type: 'metadataInput',
                model: 'siteId',
                label: 'Site',
                condition: `additionalVars.brick.${MODEL_SETTINGS}.siteType==='site'`,
                identifier: 'cm360_placementGroup-site',
                metadataType: 'cm360sites',
                useValueObject: false
            },
            {
                type: 'metadataInput',
                model: 'directorySiteId',
                condition: `additionalVars.brick.${MODEL_SETTINGS}.siteType==='directorySite'`,
                label: 'Directory site',
                identifier: 'cm360_placementGroup-directory_site',
                metadataType: 'cm360directorySites',
                useValueObject: false
            }
        ]
    },
    {
        title: 'Schedule and pricing',
        itemType: 'block',
        type: 'multiInput',
        blockModel: 'pricingSchedule',
        opened: true,
        items: [
            {
                type: 'date',
                model: 'startDate',
                identifier: 'cm360_placementGroup-pricing_schedule-start_time',
                label: 'Start date'
            },
            {
                type: 'date',
                model: 'endDate',
                identifier: 'cm360_placementGroup-pricing_schedule-end_time',
                label: 'End date'
            },
            {
                type: 'select',
                model: 'pricingType',
                label: 'Pricing type',
                identifier: 'cm360_placementGroup-pricing_schedule-pricing_type',
                tooltip: 'Pricing type',
                options: {
                    PRICING_TYPE_CPM: 'CPM',
                    PRICING_TYPE_CPC: 'CPC',
                    PRICING_TYPE_CPA: 'CPA',
                    PRICING_TYPE_FLAT_RATE_IMPRESSIONS: 'Flat rate impressions',
                    PRICING_TYPE_FLAT_RATE_CLICKS: 'Flate rate clicks',
                    PRICING_TYPE_CPM_ACTIVEVIEW: 'vCPM - Active view'
                }
            },
            {
                type: 'select',
                model: 'capCostOption',
                label: 'Cap cost option',
                identifier: 'cm360_placementGroup-pricing_schedule-cap_cost_option',
                tooltip: 'Cap cost option',
                options: {
                    CAP_COST_NONE: 'Off',
                    CAP_COST_MONTHLY: 'Monthly',
                    CAP_COST_CUMULATIVE: 'Cumulative'
                }
            },
            {
                type: 'switch',
                model: 'flighted',
                identifier: 'cm360_placementGroup-pricing_schedule-flighted',
                label: 'Flighted'
            }
        ]
    }
];

export default settings;
