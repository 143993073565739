import { IEditorBlock } from 'components/bricks/types/editorBlock.type';

const settings: IEditorBlock[] = [
    {
        title: 'General',
        itemType: 'block',
        type: 'multiInput',
        opened: true,
        items: [
            {
                type: 'text',
                model: 'title',
                label: 'Title',
                maxLength: 150,
                fixedModel: true,
                useValueObject: false
            }
        ]
    },
    {
        title: 'Creative',
        itemType: 'block',
        type: 'multiInput',
        opened: true,
        items: [
            {
                type: 'creativeSelector',
                label: 'Select your template',
                model: 'data.creatives',
                identifier: 'template_creative',
                fixedModel: true,
                templateTypes: ['dynamicImageDesigned', 'dynamicImage'],
                multiple: false
            }
        ]
    }
];

export default settings;
