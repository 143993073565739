import { IEditorBlock } from 'components/bricks/types/editorBlock.type';
import { MODEL_SETTINGS } from 'components/bricks/constants';

const settings: IEditorBlock[] = [
    {
        title: 'General',
        itemType: 'block',
        type: 'multiInput',
        opened: true,
        items: [
            {
                type: 'text',
                title: 'Block title',
                identifier: 'cm360_placement-title',
                model: 'title',
                itemType: 'input',
                label: 'Title',
                maxLength: 150,
                fixedModel: true,
                useValueObject: false
            },
            {
                type: 'dynamicData',
                title: 'Child title',
                identifier: 'cm360_placement-child_title',
                condition: `additionalVars.brick.type==='subItem' && !additionalVars.multiSocial_campaign`,
                model: 'childTitle',
                itemType: 'input',
                label: 'Child title',
                maxLength: 150,
                useValueObject: false
            },
            {
                type: 'dynamicData',
                title: 'Pointer',
                identifier: 'cm360_placement-pointer',
                condition: `additionalVars.brick.type==="subItem" && !additionalVars.multiSocial_campaign`,
                model: 'pointer',
                itemType: 'input',
                label: 'Pointer',
                maxLength: 150,
                useValueObject: false
            }
        ]
    },
    {
        title: 'Details',
        itemType: 'block',
        type: 'multiInput',
        opened: true,
        items: [
            {
                type: 'metadataInput',
                model: 'advertiserId',
                label: 'Advertiser id',
                identifier: 'cm360_placement-advertiser_id',
                metadataType: 'cm360advertisers',
                useValueObject: false
            },
            {
                type: 'buttonGroup',
                model: 'siteType',
                identifier: 'cm360_placement-site_type',
                label: 'Site type',
                tooltip: 'Campaign budget optimization',
                options: {
                    site: 'site',
                    directorySite: 'directory dite'
                }
            },
            {
                type: 'metadataInput',
                model: 'siteId',
                label: 'Site',
                condition: `additionalVars.brick.${MODEL_SETTINGS}.siteType==='site'`,
                identifier: 'cm360_placement-site_id',
                metadataType: 'cm360sites',
                useValueObject: false
            },
            {
                type: 'metadataInput',
                model: 'directorySiteId',
                condition: `additionalVars.brick.${MODEL_SETTINGS}.siteType==='directorySite'`,
                label: 'Directory site',
                identifier: 'cm360_placement-directory_site_id',
                metadataType: 'cm360directorySites',
                useValueObject: false
            },
            {
                type: 'select',
                model: 'compatibility',
                label: 'Compatibility',
                identifier: 'cm360_placement-compatibility',
                tooltip: 'Placement compatibility.',
                options: {
                    DISPLAY: 'Display',
                    DISPLAY_INTERSTITIAL: 'Display interstitial',
                    IN_STREAM_VIDEO: 'In-stream video'
                }
            },
            {
                type: 'select',
                model: 'paymentSource',
                label: 'Payment source',
                identifier: 'cm360_placement-payment_source',
                tooltip: 'Payment source for this placement. This is a required field that is read-only after insertion.',
                options: {
                    PLACEMENT_AGENCY_PAID: 'Agency',
                    PLACEMENT_PUBLISHER_PAID: 'Publisher'
                }
            },
            {
                type: 'checkboxList',
                identifier: 'cm360_placement-tag_formats',
                model: 'tagFormats',
                label: 'Tag formats',
                selectType: 'multiple',
                useValueObject: false,
                options: {
                    PLACEMENT_TAG_STANDARD: 'Standard',
                    PLACEMENT_TAG_IFRAME_JAVASCRIPT: 'Iframe/JavaScript',
                    PLACEMENT_TAG_IFRAME_ILAYER: 'Iframe\\Ilayer',
                    PLACEMENT_TAG_INTERNAL_REDIRECT: 'Internal redirect',
                    PLACEMENT_TAG_JAVASCRIPT: 'JavaScript',
                    PLACEMENT_TAG_INTERSTITIAL_IFRAME_JAVASCRIPT: 'Interstitial Iframe JavaScript',
                    PLACEMENT_TAG_INTERSTITIAL_INTERNAL_REDIRECT: 'Interstitial internal redirect',
                    PLACEMENT_TAG_INTERSTITIAL_JAVASCRIPT: 'Interstitial JavaScript',
                    PLACEMENT_TAG_CLICK_COMMANDS: 'Click tracker',
                    PLACEMENT_TAG_TRACKING: 'Tracking',
                    PLACEMENT_TAG_TRACKING_IFRAME: 'Tracking Iframe',
                    PLACEMENT_TAG_TRACKING_JAVASCRIPT: 'Tracking JavaScript'
                }
            },
            {
                type: 'autocomplete',
                model: 'sizes',
                identifier: 'cm360_placement-sizes',
                label: 'Dimensions',
                options: [
                    '88x31 (Micro bar)',
                    '120x60 (Button 2)',
                    '120x90 (Button 1)',
                    '120x240 (Vertical banner)',
                    '120x600 (Skyscraper)',
                    '125x125 (Square button)',
                    '160x600 (Wide Skyscraper)',
                    '180x150 (Rectangle)',
                    '234x60 (Half banner)',
                    '240x400'
                ]
            }
        ]
    },
    {
        title: 'Pricing details',
        itemType: 'block',
        type: 'multiInput',
        blockModel: 'pricingSchedule',
        opened: true,
        items: [
            {
                type: 'date',
                model: 'startDate',
                identifier: 'cm360_placement-pricing_schedule-start_time',
                label: 'Start date'
            },
            {
                type: 'date',
                model: 'endDate',
                identifier: 'cm360_placement-pricing_schedule-end_time',
                label: 'End date'
            },
            {
                type: 'select',
                model: 'pricingType',
                label: 'Pricing type',
                identifier: 'cm360_placement-pricing_schedule-pricing_type',
                tooltip: 'Pricing type',
                options: {
                    PRICING_TYPE_CPM: 'CPM',
                    PRICING_TYPE_CPC: 'CPC',
                    PRICING_TYPE_CPA: 'CPA',
                    PRICING_TYPE_FLAT_RATE_IMPRESSIONS: 'Flat rate impressions',
                    PRICING_TYPE_FLAT_RATE_CLICKS: 'Flate rate clicks',
                    PRICING_TYPE_CPM_ACTIVEVIEW: 'vCPM - Active view'
                }
            },
            {
                type: 'select',
                model: 'capCostOption',
                label: 'Cap cost option',
                identifier: 'cm360_placement-pricing_schedule-cap_cost_option',
                tooltip: 'Placement cap cost option.',
                options: {
                    CAP_COST_NONE: 'Off',
                    CAP_COST_MONTHLY: 'Monthly',
                    CAP_COST_CUMULATIVE: 'Cumulative'
                }
            },
            {
                type: 'switch',
                model: 'flighted',
                identifier: 'cm360_placement-pricing_schedule-flighted',
                label: 'Flighted'
            }
        ]
    }
];

export default settings;
