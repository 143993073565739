import { BrickSetup } from 'components/bricks/types/brick.type';

const setup: BrickSetup = {
    subType: 'cm360_campaign',
    title: 'Google CM - Campaign',
    channel: 'display',
    platform: 'cm360',
    description: 'Create a campaign in campaign manager.',
    addBrickText: 'Add campaign',
    outputAction: 'publish',
    availableParentBricks: [],
    availableChildBricks: ['cm360_placementGroup', 'cm360_placement'],
    additionalVars: ['feed_custom'],
    tabs: [
        {
            key: 'settings',
            title: 'Settings',
            type: 'multiInput',
            component: 'settings'
        },
        {
            key: 'briefing',
            title: 'Briefing',
            type: 'component',
            component: 'briefing'
        },
        {
            key: 'publish',
            title: 'Publish',
            type: 'component',
            component: 'output'
        }
    ]
};

export default setup;
