import moment from 'moment';

const startTime = moment().format('YYYY-MM-DD HH:mm:ss');
const endTime = moment().add(3, 'd').format('YYYY-MM-DD HH:mm:ss');

const defaultData = {
    data: {
        settings: {
            active: false,
            startTime,
            endTime,
            type: 'AD_SERVING_STANDARD_AD',
            //TODO: Add when add creative
            // creativeRotation: {
            //     type: 'CREATIVE_ROTATION_TYPE_SEQUENTIAL'
            // },
            clickThroughUrlSuffixProperties: {
                overrideInheritedSuffix: false
            },
            deliverySchedule: {
                hardCutoff: false,
                priority: 'AD_PRIORITY_01',
                impressionRatio: 1,
                frequencyCap: {
                    impressions: 1,
                    duration: 3
                }
            },
            clickThroughUrl: {
                defaultLandingPage: true
            }
        }
    }
};

export default defaultData;
