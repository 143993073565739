import output from 'components/bricks/components/shared/components/output-tab';
import settings from './settings';
import setup from './setup';
import icon from './icon';
import creative from './creative';
import validators from './validators';
import briefing from './briefing';
import defaultData from './default-data';
import widget from './widget';

export default { settings, setup, icon, creative, briefing, defaultData, widget, output, validators };
