import { ValidationObject } from 'components/bricks/types/validator.type';

const validators: ValidationObject = {
    validators: [
        {
            condition: '!brick.data?.settings?.advertiserId',
            message: 'Advertiser id is required for CM360 Campaign',
            inputIdentifiers: ['cm360_campaign-advertiser_id'],
            tabKey: 'settings',
            severity: 'error'
        },
        {
            condition: '!brick.data?.settings?.startTime',
            message: 'Start date of the campaign is required',
            inputIdentifiers: ['cm360_ad-start_time'],
            tabKey: 'settings',
            severity: 'error'
        },
        {
            condition: '!brick.data?.settings?.endTime',
            message: 'End date of the ad is required',
            inputIdentifiers: ['cm360_ad-end_time'],
            tabKey: 'settings',
            severity: 'error'
        },
        {
            condition: '!brick.data?.settings?.type',
            message: 'Ad type is required',
            inputIdentifiers: ['cm360_ad-type'],
            tabKey: 'settings',
            severity: 'error'
        },
        {
            condition: 'brick.data?.settings?.type === "AD_SERVING_STANDARD_AD" && !brick.data?.settings?.deliverySchedule.priority',
            message: 'Ad priority is required when the type of the ad is `Ad serving standard ad`',
            inputIdentifiers: ['cm360_ad-priority'],
            tabKey: 'settings',
            severity: 'error'
        },
        {
            condition: 'brick.data?.settings?.type === "AD_SERVING_STANDARD_AD" && !brick.data?.settings?.deliverySchedule.impressionRatio',
            message: 'Impression ratio is required when the type of the ad is `Ad serving standard ad`',
            inputIdentifiers: ['cm360_ad-priority'],
            tabKey: 'settings',
            severity: 'error'
        },
        {
            condition:
                'brick.data?.settings?.type === "AD_SERVING_STANDARD_AD" && (brick.data?.settings?.deliverySchedule?.frequencyCap?.impressions?<1 || brick.data?.settings?.deliverySchedule?.frequencyCap?.impressions?>15)',
            message: 'Frequency cap impressions should be between 1 and 15',
            inputIdentifiers: ['cm360_ad-frequency_cap-impressions'],
            tabKey: 'settings',
            severity: 'error'
        },
        {
            condition:
                'brick.data?.settings?.type === "AD_SERVING_STANDARD_AD" && (brick.data?.settings?.deliverySchedule?.frequencyCap?.duration?<1 || brick.data?.settings?.deliverySchedule?.frequencyCap?.duration?>7776000)',
            message: 'Frequency cap duration should be between 1 and 7776000',
            inputIdentifiers: ['cm360_ad-frequency_cap-duration'],
            tabKey: 'settings',
            severity: 'error'
        },
        {
            condition:
                'brick.data?.settings?.type === "AD_SERVING_CLICK_TRACKER" && !brick.data?.settings?.clickThroughUrl?.defaultLandingPage && (!brick.data?.settings?.clickThroughUrl?.defaultLandingPage || !brick.data?.settings?.clickThroughUrl?.customClickThroughUrl)',
            message:
                'When you prefer not to use default landing page please specify landing page or custom click through url when ad type is `Ad serving click tracker`',
            inputIdentifiers: ['cm360_ad-click_through_url-default_landing_page'],
            tabKey: 'settings',
            severity: 'error'
        }
    ]
};

export default validators;
