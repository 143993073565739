import React, { useState } from 'react';
import TabWrapper from 'components/bricks/components/shared/components/tab-wrapper';
import { ACCEPTED_FILE_TYPES } from 'components/bricks/constants';
import BrickHelpers from 'components/bricks/helpers/brick.helpers';
import { BricksComponentStore } from 'components/bricks/types/bricksComponentStore.type';
import useComponentStore from 'components/data/ComponentStore/hooks/useComponentStore';
import { BricksMediaInput } from 'components/bricks/components/shared/components/creative-input/components/bricks-media-input';
import { SourceData } from 'components/assets/AssetGalleryDialog/interfaces/AssetGalleryDialogState';
import Translation from 'components/data/Translation';
import AssetSetHelper from 'components/bricks/helpers/asset-set-helper';
import Accordion from 'components/bricks/components/shared/components/accordion';
import { AssetSetOverview } from './components/asset-set-overview';

interface ComponentStoreProps {
    activeItemId: BricksComponentStore['activeItemId'];
    model: BricksComponentStore['model'];
    isLoading: boolean;
}

/** Upload tab component for the asset set. */
const Upload: React.FC = () => {
    const [uploadsLoading, setUploadsLoading] = useState<string[]>([]);
    const [showAssetGalleryDialog, setShowAssetGalleryDialog] = useState(false);
    const { activeItemId } = useComponentStore<ComponentStoreProps>('Bricks', { fields: { activeItemId: 'activeItemId' } });

    /**
     * Add a new loading asset to the loading array to show the loading spinner for the asset.
     * @param title Title of the asset.
     */
    const addUploadsLoading = (title: string | undefined) => {
        if (!title) return;

        setUploadsLoading((prev) => [...prev, title]);
    };

    /**
     * Remove a loading asset from the loading array to hide the loading spinner for the asset.
     * @param title Title of the asset.
     */
    const removeUploadsLoading = (title: string | undefined) => {
        if (!title) return;

        setUploadsLoading((prev) => prev.filter((item) => item !== title));
    };

    /**
     * Handle the asset mutation for the uploaded asset.
     * @param sourceData Source data coming from the FileUpload component.
     * @param dataType Data type of the source data.
     * @param onValidationStart Callback function to be called when the validation starts.
     * @param onValidationComplete Callback function to be called when the validation is completed.
     */
    const handleAssetMutation = async (sourceData: SourceData, dataType?: string) => {
        const brick = BrickHelpers.getBrickById(activeItemId);

        if (dataType === 'sourceData') return; // If the data type is source data, return.

        addUploadsLoading(sourceData.title); // Show the loading spinner for the asset to indicate that the asset is being validated.
        await AssetSetHelper.handleMutation(sourceData, brick, dataType);
        removeUploadsLoading(sourceData.title); // Remove the loading spinner for the asset to indicate that the asset is finished validating.
    };

    return (
        <TabWrapper>
            <Accordion title={Translation.get('uploadCreatives', 'bricks')}>
                <BricksMediaInput
                    fileType={ACCEPTED_FILE_TYPES}
                    onHandleAssetMutation={(assetData, dataType) => handleAssetMutation(assetData, dataType)}
                    setShowAssetGalleryDialog={setShowAssetGalleryDialog}
                    showAssetGalleryDialog={showAssetGalleryDialog}
                />
            </Accordion>
            <AssetSetOverview uploadsLoading={uploadsLoading} />
        </TabWrapper>
    );
};

export default Upload;
