import { BrickSetup } from 'components/bricks/types/brick.type';

const setup: BrickSetup = {
    subType: 'feed_custom',
    title: 'Feed | Custom setup',
    channel: 'feed',
    description: 'Setup a custom feed and connect one or more items to it.',
    addBrickText: 'Add custom feed',
    availableParentBricks: [],
    outputAction: 'publish',
    availableChildBricks: ['all'],
    defaultTab: 'feed',
    tabs: [
        {
            key: 'settings',
            title: 'Settings',
            type: 'multiInput'
        },
        {
            key: 'feed',
            title: 'Feed',
            type: 'component',
            component: 'feed'
        },
        {
            key: 'briefing',
            title: 'Briefing',
            type: 'component',
            component: 'briefing'
        },
        {
            key: 'publish',
            title: 'Publish',
            type: 'component',
            component: 'output'
        }
    ]
};

export default setup;
