import React, { useState, useMemo } from 'react';
import Icon from 'components/ui-components-v2/Icon';
import VisualStackEditorHelpers from 'components/data/VisualStackEditorHelpers';
import BrickHelpers from 'components/bricks/helpers/brick.helpers';
import EditorData from 'components/editor-data/EditorData';
import EditorBlock from 'components/editor-base/EditorBlock';
import TabWrapper from 'components/bricks/components/shared/components/tab-wrapper';
import Button from 'components/ui-components-v2/Button';
import EmailEditor, { EmailEditorResonsiveSwitch } from 'components/channels/email/EmailEditor';
import { VisualStackEditorAbTestTabbar, VisualStackEditorAlertBox } from 'components/ui-base/VisualStackEditor';
import { RenderEmailDynamicData } from 'components/channels/email/EmailPreview';
import './styles/preview.scss';

interface Props {
    blockModel: string;
}

const EmailPreview: React.FC<Props> = ({ blockModel }) => {
    const [editorOpen, setEditorOpen] = useState<boolean>(false);
    const [mobilePreview, setMobilePreview] = useState<boolean>(false);
    const groupKey = useMemo(() => EditorData.getValueFromModel(`${blockModel}.data.groupKey`), []);
    const abTesting = VisualStackEditorHelpers.getAbTestSetting(`${blockModel}.data.settings`);
    const [abTestGroupsShown, setAbTestGroupsShown] = useState(abTesting ? [...abTesting] : false);

    return (
        <React.Fragment>
            <TabWrapper classes={{ root: 'bricks-email-preview' }}>
                <div className="bricks-email-preview__wrapper">
                    <div className="bricks-email-preview__wrapper__toolbar">
                        <div>
                            {abTesting && (
                                <VisualStackEditorAbTestTabbar
                                    abTesting={abTesting}
                                    onSetAbTestGroupsShown={setAbTestGroupsShown}
                                    abTestGroupsShown={abTestGroupsShown}
                                    className="bricks-email-preview__wrapper__ab-testing"
                                />
                            )}
                        </div>
                        <Button onClick={() => setEditorOpen(true)} variant="contained" startIcon={<Icon>edit</Icon>}>
                            Open editor
                        </Button>
                    </div>
                    <div className="bricks-email-preview__wrapper__content">
                        <div className="bricks-email-preview__wrapper__form">
                            <EditorBlock
                                compact
                                data={{
                                    blockModel: `${blockModel}.data.settings`,
                                    items: BrickHelpers.getBrickData('email', 'metadata')
                                }}
                            />
                        </div>
                        <div className="bricks-email-preview__wrapper__preview">
                            <VisualStackEditorAlertBox
                                abTesting={abTesting}
                                abTestGroupsShown={abTestGroupsShown}
                                className="bricks-email-preview__wrapper__alert"
                            />
                            <div className="bricks-email-preview__wrapper__switch-container">
                                <EmailEditorResonsiveSwitch
                                    onSetMobile={() => setMobilePreview(!mobilePreview)}
                                    mobilePreview={mobilePreview}
                                    className="bricks-email-preview__wrapper__responsive-switch"
                                />
                            </div>
                            {!editorOpen && (
                                <RenderEmailDynamicData
                                    data={{ model: `${blockModel}.data.setup` }}
                                    additionalProps={{
                                        groupKey,
                                        model: `${blockModel}.data.setup`,
                                        dataModel: `${blockModel}.data.blocks`,
                                        settingsModel: `${blockModel}.data.settings`,
                                        mobilePreview,
                                        abTesting,
                                        abTestGroupsShown,
                                        onOpenEditor: () => setEditorOpen(true)
                                    }}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </TabWrapper>
            {editorOpen && (
                <EmailEditor
                    additionalProps={{ onClose: () => setEditorOpen(false), dataModel: `${blockModel}.data.blocks`, groupKey }}
                    data={{
                        model: `${blockModel}.data.setup`,
                        dataModel: `${blockModel}.data.blocks`,
                        sourceDataModel: `${blockModel}.data.blocks`,
                        settingsModel: `${blockModel}.data.settings`
                    }}
                />
            )}
        </React.Fragment>
    );
};

export default EmailPreview;
