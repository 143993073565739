import { IEditorBlock } from 'components/bricks/types/editorBlock.type';

const briefing: IEditorBlock[] = [
    {
        title: 'General',
        itemType: 'block',
        type: 'multiInput',
        opened: true,
        items: [
            {
                type: 'text',
                multiline: true,
                model: 'title',
                itemType: 'input',
                label: 'Title of the brick',
                useValueObject: false
            },
            {
                type: 'text',
                multiline: true,
                identifier: 'description',
                model: 'data.description',
                itemType: 'input',
                label: 'Description',
                useValueObject: false
            }
        ]
    }
];

export default briefing;
