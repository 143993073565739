import { ValidationObject } from 'components/bricks/types/validator.type';

const validators: ValidationObject = {
    validators: [
        {
            condition: '!brick.data.creatives || brick.data.creatives.length === 0',
            message: 'No creative found. Please add a creative',
            inputIdentifiers: ['template_creative'],
            tabKey: 'settings',
            severity: 'error'
        }
    ]
};

export default validators;
