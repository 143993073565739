import moment from 'moment';
import React from 'react';
import Icon from 'components/ui-components-v2/Icon';
import './index.scss';

const widget = () => {
    return (
        <div className="feed-widget">
            <div className="feed-widget__icon">
                <Icon>check_circle_outline</Icon>
            </div>
            <div className="feed-widget__status">
                <div className="feed-widget__status__title">Updating...</div>
                <div className="feed-widget__status__date">{moment().format('Do MMM YYYY hh:mm:ss')}</div>
            </div>

            <div className="feed-widget__details">
                <div className="feed-widget__details__chip feed-widget__details__chip--updated">4</div>
                <div className="feed-widget__details__chip feed-widget__details__chip--progress">4</div>
                <div className="feed-widget__details__chip feed-widget__details__chip--error">4</div>
            </div>
        </div>
    );
};

export default widget;
