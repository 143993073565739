import { IEditorBlock } from 'components/bricks/types/editorBlock.type';
import { MODEL_SETTINGS } from 'components/bricks/constants';

const settings: IEditorBlock[] = [
    {
        title: 'General',
        itemType: 'block',
        type: 'multiInput',
        opened: true,
        items: [
            {
                type: 'text',
                title: 'Block title',
                identifier: 'cm360_campaign-title',
                model: 'title',
                itemType: 'input',
                fixedModel: true,
                label: 'Title',
                maxLength: 150,
                useValueObject: false
            },
            {
                type: 'dynamicData',
                title: 'Child title',
                identifier: 'cm360_campaign-child_title',
                condition: `additionalVars.brick.type==='subItem' && !additionalVars.multiSocial_campaign`,
                model: 'childTitle',
                itemType: 'input',
                label: 'Child title',
                maxLength: 150,
                useValueObject: false
            },
            {
                type: 'dynamicData',
                title: 'Pointer',
                identifier: 'cm360_campaign-pointer',
                condition: `additionalVars.brick.type==="subItem" && !additionalVars.multiSocial_campaign`,
                model: 'pointer',
                itemType: 'input',
                label: 'Pointer',
                maxLength: 150,
                useValueObject: false
            }
        ]
    },
    {
        title: 'Details',
        itemType: 'block',
        type: 'multiInput',
        opened: true,
        items: [
            {
                type: 'metadataInput',
                model: 'advertiserId',
                label: 'Advertiser id',
                identifier: 'cm360_campaign-advertiser_id',
                metadataType: 'cm360advertisers',
                useValueObject: false
            },
            {
                type: 'buttonGroup',
                model: 'defaultLandingPageOption',
                identifier: 'cm360_campaign-default_landing_page_option',
                label: 'Default landing page option',
                tooltip: 'Select wheather you want to create new landing page or use existing one',
                options: {
                    SELECT: 'Select existing',
                    CREATE: 'Create new'
                }
            },
            {
                type: 'text',
                model: 'defaultLandingPageName',
                identifier: 'cm360_campaign-default_landing_page_name',
                condition: `additionalVars.brick.${MODEL_SETTINGS}.defaultLandingPageOption === "CREATE"`,
                label: 'Landing page name',
                useValueObject: false
            },
            {
                type: 'text',
                model: 'defaultLandingPageUrl',
                identifier: 'cm360_campaign-default_landing_page_url',
                condition: `additionalVars.brick.${MODEL_SETTINGS}.defaultLandingPageOption === "CREATE"`,
                label: 'Landing page URL',
                validators: [
                    {
                        type: 'url',
                        severity: 'error',
                        message: 'This should be a valid url'
                    }
                ],
                useValueObject: false
            },
            {
                type: 'metadataInput',
                model: 'defaultLandingPageId',
                identifier: 'cm360_campaign-default_landing_page',
                condition: `additionalVars.brick.${MODEL_SETTINGS}.advertiserId && additionalVars.brick.${MODEL_SETTINGS}.defaultLandingPageOption === "SELECT"`,
                label: 'Landing page',
                metadataType: 'cm360landingPages',
                config: {
                    advertiserIds: '{{{data.settings.advertiserId}}}'
                },
                useValueObject: false
            },
            {
                type: 'date',
                model: 'startDate',
                identifier: 'cm360_campaign-start_time',
                label: 'Start date'
            },
            {
                type: 'date',
                model: 'endDate',
                identifier: 'cm360_campaign-end_time',
                label: 'End date'
            }
        ]
    }
];

export default settings;
