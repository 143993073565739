import React, { useState } from 'react';
import get from 'lodash/get';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import AddPhotoAlternateOutlinedIcon from '@mui/icons-material/AddPhotoAlternateOutlined';
import { CardContent, CardHeader } from '@mui/material';
import Icon from 'components/ui-components-v2/Icon';
import IconButton from 'components/ui-components-v2/IconButton';
import TabWrapper from 'components/bricks/components/shared/components/tab-wrapper';
import BricksCreativeInput from 'components/bricks/components/shared/components/creative-input/components';
import BrickHelpers from 'components/bricks/helpers/brick.helpers';
import Translation from 'components/data/Translation';
import { MODEL_ASSET_VALIDATION_RESULTS, MODEL_CREATIVES, MODEL_TITLE } from 'components/bricks/constants';
import SnackbarUtils from 'components/ui-base/SnackbarUtils';
import { CreativeV2CustomUpload } from 'components/creatives-v2/components/creative-editor/types/creativeV2.type';
import { BrickSetup } from 'components/bricks/types/brick.type';
import ComponentStore from 'components/data/ComponentStore';
import Accordion from 'components/bricks/components/shared/components/accordion';
import User from 'components/data/User';
import useBrick from '../../../../hooks/useBrick';

import './styles/creative.scss';
import Card from 'components/ui-components-v2/Card';

const Creative = () => {
    const [showAssetGalleryDialog, setShowAssetGalleryDialog] = useState(false);
    const { brick } = useBrick();

    if (!brick) return null;

    const activeItemId = brick.id;

    /** Remove the asset from the brick. */
    const handleDeleteAsset = () => {
        if (!activeItemId) return;

        const brickSetup = BrickHelpers.getBrickData<BrickSetup | undefined>(brick.subType, 'setup');
        const brickTitle = brickSetup?.title ?? '';
        const brickPrefix = BrickHelpers.getBrickPrefix(activeItemId);

        ComponentStore.setMultiModels('Bricks', [
            [`${brickPrefix}.${MODEL_CREATIVES}`, undefined],
            [`${brickPrefix}.${MODEL_ASSET_VALIDATION_RESULTS}`, undefined],
            [`${brickPrefix}.${MODEL_TITLE}`, brickTitle]
        ]);

        SnackbarUtils.success(Translation.get('assetRemoved', 'bricks'));
    };

    const getActions = () => {
        if (!User.hasRight('brickManagement')) return null;

        const creatives: CreativeV2CustomUpload[] | undefined = get(brick, MODEL_CREATIVES);

        return (
            <div className="single-asset-creative">
                <IconButton onClick={() => setShowAssetGalleryDialog(!showAssetGalleryDialog)} size="small">
                    {/** The reason for importing the whole MUI icon component is because the icon renders faster than using the MUI outline classes */}
                    {creatives && creatives.length > 0 ? <EditOutlinedIcon /> : <AddPhotoAlternateOutlinedIcon />}
                </IconButton>
                {creatives && (
                    <IconButton onClick={() => handleDeleteAsset()} size="small">
                        <Icon>delete_outline</Icon>
                    </IconButton>
                )}
            </div>
        );
    };

    return (
        <TabWrapper classes={{ root: 'single-asset-tab-wrapper' }}>
            <Card style={{ height: '100%' }}>
                <CardHeader title={Translation.get('labels.creative')} action={getActions()} classes={{ root: 'single-asset-tab-wrapper__card-header' }} />
                <CardContent style={{ height: 'calc(100% - 60px)' }}>
                    <BricksCreativeInput brick={brick} showAssetGalleryDialog={showAssetGalleryDialog} setShowAssetGalleryDialog={setShowAssetGalleryDialog} />
                </CardContent>
            </Card>
        </TabWrapper>
    );
};

export default Creative;
