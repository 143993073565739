import { BrickSetup } from 'components/bricks/types/brick.type';
import baseSetup from '../all/setup';

const setup: BrickSetup = {
    ...baseSetup,
    subType: 'creative_video',
    title: 'Video creative',
    channel: 'assets',
    description: 'A creative based on a video template',
    addBrickText: 'Add video creative brick',
    config: {
        ...baseSetup.config,
        general: {
            ...baseSetup?.config?.general,
            creativeSelector: {
                ...baseSetup?.config?.general?.creativeSelector,
                templateTypes: ['dynamicVideoDesigned', 'dynamicVideo', 'dynamicAfterEffects']
            }
        }
    }
};

export default setup;
