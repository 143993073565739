import moment from 'moment';

const startDate = moment().format('YYYY-MM-DD HH:mm:ss');
const endDate = moment().add(3, 'd').format('YYYY-MM-DD HH:mm:SS');

const defaultData = {
    data: {
        settings: {
            siteType: 'site',
            pricingSchedule: {
                pricingType: 'PRICING_TYPE_CPM',
                startDate,
                endDate,
                flighted: false,
                capCostOption: 'CAP_COST_NONE'
            }
        }
    }
};

export default defaultData;
