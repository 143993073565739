import moment from 'moment';

const startDate = moment().format('YYYY-MM-DD HH:mm:ss');
const endDate = moment().add(3, 'd').format('YYYY-MM-DD HH:mm:ss');

const defaultData = {
    data: {
        settings: {
            defaultLandingPageOption: 'SELECT',
            startDate,
            endDate
        }
    }
};

export default defaultData;
