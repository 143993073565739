import output from 'components/bricks/components/shared/components/output-tab';
import settings from './settings';
import setup from './setup';
import validators from './validators';
import icon from './icon';
import upload from './upload';
import defaultData from './default-data';
import briefing from './briefing';
import briefingInputs from './briefing-inputs';
import creatives from './creatives';
export default { settings, setup, icon, upload, defaultData, briefing, briefingInputs, creatives, output, validators };
