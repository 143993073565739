import { IEditorBlock } from 'components/bricks/types/editorBlock.type';
import { MODEL_PUBLISH_METADATA, MODEL_SETTINGS } from 'components/bricks/constants';

const settings: IEditorBlock[] = [
    {
        title: 'General',
        itemType: 'block',
        type: 'multiInput',
        opened: true,
        items: [
            {
                type: 'text',
                title: 'Block title',
                identifier: 'cm360_ad-title',
                model: 'title',
                itemType: 'input',
                label: 'Title',
                maxLength: 150,
                fixedModel: true,
                useValueObject: false
            },
            {
                type: 'dynamicData',
                title: 'Child title',
                identifier: 'cm360_ad-child_title',
                condition: `additionalVars.brick.type==='subItem' && !additionalVars.multiSocial_campaign`,
                model: 'childTitle',
                itemType: 'input',
                label: 'Child title',
                maxLength: 150,
                useValueObject: false
            },
            {
                type: 'dynamicData',
                title: 'Pointer',
                identifier: 'cm360_ad-pointer',
                condition: `additionalVars.brick.type==="subItem" && !additionalVars.multiSocial_campaign`,
                model: 'pointer',
                itemType: 'input',
                label: 'Pointer',
                maxLength: 150,
                useValueObject: false
            }
        ]
    },
    {
        title: 'Details',
        itemType: 'block',
        type: 'multiInput',
        opened: true,
        items: [
            {
                type: 'metadataInput',
                model: 'advertiserId',
                label: 'Advertiser id',
                identifier: 'cm360_ad-advertiser_id',
                metadataType: 'cm360advertisers',
                useValueObject: false
            },
            {
                type: 'switch',
                model: 'active',
                identifier: 'cm360_ad-active',
                label: 'Status'
            },
            {
                type: 'date',
                model: 'startTime',
                identifier: 'cm360_ad-start_time',
                label: 'Start time'
            },
            {
                type: 'date',
                model: 'endTime',
                identifier: 'cm360_ad-end_time',
                label: 'End date'
            },
            {
                type: 'select',
                model: 'type',
                label: 'Type',
                identifier: 'cm360_ad-type',
                tooltip: 'Type of ad. This is a required field on insertion.',
                options: {
                    AD_SERVING_STANDARD_AD: 'Ad serving standard ad',
                    // TODO: Add when we add creative
                    // AD_SERVING_DEFAULT_AD: 'Ad serving default ad',
                    AD_SERVING_CLICK_TRACKER: 'Ad serving click tracker',
                    AD_SERVING_TRACKING: 'Ad serving trackng'
                }
            }
        ]
    },
    {
        title: 'Delivery schedule',
        itemType: 'block',
        type: 'multiInput',
        blockModel: 'deliverySchedule',
        opened: true,
        items: [
            {
                type: 'switch',
                model: 'hardCutoff',
                identifier: 'cm360_ad-hard_cutoff',
                label: 'Hard cutoff'
            },
            {
                type: 'select',
                model: 'priority',
                label: 'Priority',
                identifier: 'cm360_ad-priority',
                tooltip: 'Priority',
                options: {
                    AD_PRIORITY_01: '1 - HIGHEST',
                    AD_PRIORITY_02: '2',
                    AD_PRIORITY_03: '3',
                    AD_PRIORITY_04: '4',
                    AD_PRIORITY_05: '5',
                    AD_PRIORITY_06: '6',
                    AD_PRIORITY_07: '7',
                    AD_PRIORITY_08: '8',
                    AD_PRIORITY_09: '9',
                    AD_PRIORITY_10: '10',
                    AD_PRIORITY_11: '11',
                    AD_PRIORITY_12: '12',
                    AD_PRIORITY_13: '13',
                    AD_PRIORITY_14: '14',
                    AD_PRIORITY_15: '15 - STANDARD AD',
                    AD_PRIORITY_16: '16 - DEFAULT AD'
                }
            },
            {
                type: 'number',
                identifier: 'cm360_ad-impression_ratio',
                tooltip:
                    'Impression ratio for this ad. This ratio determines how often each ad is served relative to the others. For example, if ad A has an impression ratio of 1 and ad B has an impression ratio of 3, then Campaign Manager will serve ad B three times as often as ad A. Acceptable values are',
                model: 'impressionRatio',
                label: 'Impression ratio'
            },
            {
                type: 'number',
                identifier: 'cm360_ad-frequency_cap-impressions',
                tooltip:
                    'Impression ratio for this ad. This ratio determines how often each ad is served relative to the others. For example, if ad A has an impression ratio of 1 and ad B has an impression ratio of 3, then Campaign Manager will serve ad B three times as often as ad A. Acceptable values are',
                model: 'frequencyCap.impressions',
                label: 'Impressions'
            },
            {
                type: 'number',
                identifier: 'cm360_ad-frequency_cap-duration',
                tooltip: 'Duration of time, in seconds, for this frequency cap. The maximum duration is 90 days. Acceptable values are',
                model: 'frequencyCap.duration',
                label: 'Duration'
            }
        ]
    },
    // TODO: Add when add creative
    // {
    //     title: 'Creative rotation',
    //     itemType: 'block',
    //     type: 'multiInput',
    //     blockModel: 'creativeRotation',
    //     opened: true,
    //     items: [
    //         {
    //             type: 'select',
    //             model: 'type',
    //             label: 'Type',
    //             identifier: 'cm360_ad-creative_rotation_type',
    //             tooltip: 'Type of creative rotation. Can be used to specify whether to use sequential or random rotation.',
    //             options: { CREATIVE_ROTATION_TYPE_SEQUENTIAL: 'Sequential', CREATIVE_ROTATION_TYPE_RANDOM: 'Random' }
    //         },
    //         {
    //             type: 'select',
    //             model: 'weightCalculationStrategy',
    //             label: 'Weight Calculation Strategy',
    //             identifier: 'cm360_ad-creative_rotation_weight-calculation_strategy',
    //             condition: `additionalVars.brick.${MODEL_SETTINGS}.creativeRotation?.type`,
    //             tooltip: 'Strategy for calculating weights.',
    //             options: {
    //                 WEIGHT_STRATEGY_EQUAL: 'Equal',
    //                 WEIGHT_STRATEGY_CUSTOM: 'Custom',
    //                 WEIGHT_STRATEGY_HIGHEST_CTR: 'CTR',
    //                 WEIGHT_STRATEGY_OPTIMIZED: 'Optimized'
    //             }
    //         }
    //     ]
    // },
    {
        title: 'Url suffix',
        itemType: 'block',
        type: 'multiInput',
        blockModel: 'clickThroughUrlSuffixProperties',
        opened: true,
        items: [
            {
                type: 'switch',
                model: 'overrideInheritedSuffix',
                identifier: 'cm360_ad-override-inherited-suffix',
                label: 'Override inherited suffix',
                tooltip: 'Whether this entity should override the inherited click-through URL suffix with its own defined value.'
            },
            {
                type: 'text',
                identifier: 'cm360_ad-click-through-url-suffix',
                model: 'clickThroughUrlSuffix',
                itemType: 'input',
                label: 'Click-through URL suffix',
                useValueObject: false
            }
        ]
    },
    {
        title: 'Click through url',
        itemType: 'block',
        type: 'multiInput',
        blockModel: 'clickThroughUrl',
        opened: true,
        items: [
            {
                type: 'switch',
                model: 'defaultLandingPage',
                identifier: 'cm360_ad-click_through_url-default_landing_page',
                label: 'Default landing page',
                tooltip: 'Whether the campaign default landing page is used.'
            },
            {
                type: 'switch',
                model: 'isLandingPage',
                identifier: 'cm360_ad-click_through_url-is_landing_page',
                condition: `!additionalVars.brick.${MODEL_SETTINGS}.clickThroughUrl?.defaultLandingPage`,
                label: 'Is landing page?',
                tooltip: 'Whether the campaign default landing page is used.'
            },
            {
                type: 'metadataInput',
                model: 'landingPageId',
                identifier: 'cm360_ad-click_through_url-landing_page',
                condition: `!additionalVars.brick.${MODEL_SETTINGS}.clickThroughUrl?.defaultLandingPage && additionalVars.brick.${MODEL_SETTINGS}.clickThroughUrl?.isLandingPage`,
                label: 'Landing page',
                metadataType: 'cm360landingPages',
                config: {
                    advertiserIds: '{{{data.settings.advertiserId}}}'
                },
                useValueObject: false
            },
            {
                type: 'text',
                model: 'customClickThroughUrl',
                label: 'Custom click through url',
                identifier: 'cm360_ad-click_through_url-custom_click_through_url',
                condition: `!additionalVars.brick.${MODEL_SETTINGS}.clickThroughUrl?.defaultLandingPage && !additionalVars.brick.${MODEL_SETTINGS}.clickThroughUrl?.isLandingPage`,
                helperText: 'Custom click-through URL.',
                useValueObject: false
            }
        ]
    }
];

export default settings;
