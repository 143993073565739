import React from 'react';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import classNames from 'classnames';
import { BricksComponentStore, BricksObject } from 'components/bricks/types/bricksComponentStore.type';
import useComponentStore from 'components/data/ComponentStore/hooks/useComponentStore';
import BrickHelpers from 'components/bricks/helpers/brick.helpers';
import TypeIcon from 'components/bricks/components/shared/components/type-icon';
import SnackbarUtils from 'components/ui-base/SnackbarUtils';
import Translation from 'components/data/Translation';
import { MODEL_TITLE } from 'components/bricks/constants';
import { Brick } from 'components/bricks/types/brick.type';
import BricksComponentStoreHelper from 'components/bricks/helpers/bricks-component-store.helper';
import Accordion from 'components/bricks/components/shared/components/accordion';
import useBrick from '../../../../../hooks/useBrick';
import AssetSetOverviewTableRow from './asset-set-overview-table-row';

import './styles/asset-set-overview.scss';

interface ComponentStoreProps {
    activeTab: BricksComponentStore['activeTab'];
    bricks: BricksObject;
}

interface Props {
    uploadsLoading: string[];
}

/** Asset set overview component for the upload tab. */
const AssetSetOverview = ({ uploadsLoading }: Props) => {
    const { activeTab } = useComponentStore<ComponentStoreProps>('Bricks', {
        fields: { activeTab: 'activeTab' }
    });
    const { brickChildren } = useBrick();

    /**
     * Handle the delete brick action.
     * @param brickId The brick id to delete.
     */
    const handleOnDeleteBrick = async (brickId?: string) => {
        if (!brickId) return;

        const isDeleted = await BrickHelpers.handleDeleteBrick(brickId); // Handle the delete brick action.

        if (!isDeleted) {
            SnackbarUtils.error(Translation.get('feedback.errors.oops', 'common'));
            return;
        }

        SnackbarUtils.success(Translation.get('feedback.success.brickDeleted', 'bricks'));
    };

    /**
     * Check if the current item is the last item in the validation list.
     * @param index The index of the current item.
     */
    const isLastValidationItem = (index: number) => {
        const lastIndex = uploadsLoading.length - 1; // Get the last index in the array

        if (lastIndex <= -1) return false; // If there are no items in the array, return false

        return index === lastIndex; // If the index is the last index in the array, return true
    };

    /**
     * Update the title of the brick.
     * @param newTitle The new title of the brick.
     * @param brick The brick to update.
     */
    const handleTitleChange = (newTitle: string, brick: Brick) => {
        const brickId = brick?.id;

        if (!brickId) return; // If there is no brick id return.

        BricksComponentStoreHelper.setBrickModel(brickId, MODEL_TITLE, newTitle); // Set the new title in the component store.
    };

    if (brickChildren.length === 0 && uploadsLoading.length === 0) return null; // If there are no children, return null

    return (
        <Accordion title={'Overview'}>
            <div className="asset-set-overview">
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell classes={{ root: 'asset-set-overview__table__header__row-cell' }}>{Translation.get('labels.title', 'common')}</TableCell>
                            <TableCell classes={{ root: 'asset-set-overview__table__header__row-cell' }}>
                                {Translation.get('presetValidation', 'bricks')}
                            </TableCell>
                            <TableCell classes={{ root: 'asset-set-overview__table__header__row-cell' }}></TableCell>
                        </TableRow>
                    </TableHead>
                    {uploadsLoading.map((title, index) => {
                        const isLastItem = isLastValidationItem(index);

                        return (
                            <TableRow key={index + title}>
                                <TableCell
                                    classes={{
                                        root: classNames(
                                            'asset-set-overview__table__validation__row-cell',
                                            isLastItem ? 'asset-set-overview__table__validation__row-cell-last' : ''
                                        )
                                    }}>
                                    <div className="asset-set-overview__item__title-box">
                                        <div className="asset-set-overview__item__title-box__icon">
                                            <TypeIcon warnings={[]} errors={[]} isLoading subType={undefined} />
                                        </div>
                                        <div className="asset-set-overview__item__title-box__title">{title}</div>
                                    </div>
                                </TableCell>
                                <TableCell
                                    classes={{
                                        root: classNames(
                                            'asset-set-overview__table__validation__row-cell',
                                            isLastItem ? 'asset-set-overview__table__validation__row-cell-last' : ''
                                        )
                                    }}></TableCell>
                                <TableCell
                                    classes={{
                                        root: classNames(
                                            'asset-set-overview__table__validation__row-cell',
                                            isLastItem ? 'asset-set-overview__table__validation__row-cell-last' : ''
                                        )
                                    }}></TableCell>
                            </TableRow>
                        );
                    })}
                    {brickChildren.map((child) => {
                        return (
                            <AssetSetOverviewTableRow
                                key={child.id}
                                child={child}
                                activeTab={activeTab}
                                deleteBrick={handleOnDeleteBrick}
                                handleTitleChange={handleTitleChange}
                            />
                        );
                    })}
                </Table>
            </div>
        </Accordion>
    );
};

export { AssetSetOverview };
