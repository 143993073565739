import { BrickSetup } from 'components/bricks/types/brick.type';
import baseSetup from '../all/setup';

const setup: BrickSetup = {
    ...baseSetup,
    subType: 'creative_image',
    title: 'Image creative',
    channel: 'assets',
    description: 'A creative based on an image template',
    addBrickText: 'Add image creative brick',
    config: {
        ...baseSetup.config,
        general: {
            ...baseSetup?.config?.general,
            creativeSelector: {
                ...baseSetup?.config?.general?.creativeSelector,
                templateTypes: ['dynamicImageDesigned', 'dynamicImage']
            }
        }
    }
};

export default setup;
