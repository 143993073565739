import { ValidationObject } from 'components/bricks/types/validator.type';

const validators: ValidationObject = {
    validators: [
        {
            condition: '!children || !children.length',
            message: 'The media set does not have any assigned media bricks to download',
            inputIdentifiers: [],
            tabKey: 'upload',
            severity: 'error'
        }
    ]
};

export default validators;
