import React from 'react';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import IconButton from 'components/ui-components-v2/IconButton';
import Icon from 'components/ui-components-v2/Icon';
import AssetWarningMessage from 'components/bricks/components/shared/components/asset-warning-message';
import TypeIcon from 'components/bricks/components/shared/components/type-icon';
import BrickSetupHelper from 'components/bricks/helpers/brick-setup-helper';
import { setActiveItem } from 'components/bricks/helpers/slide-panel.helpers';
import TextInlineEdit from 'components/ui-components/TextInlineEdit';
import Tooltip from 'components/ui-components-v2/Tooltip';
import { Brick } from 'components/bricks/types/brick.type';
import { UnifiedTab } from 'components/bricks/types/bricksComponentStore.type';
import useBrick from '../../../../../hooks/useBrick';

import './styles/asset-set-overview-table-row.scss';

interface Props {
    child: Brick;
    activeTab?: UnifiedTab;
    deleteBrick: (brickId: string) => void;
    handleTitleChange: (value: string, brick: Brick) => void;
}

/**
 * Asset set overview table row component.
 * This component was created in order to prevent re-rendering when only one row is updated.
 */
const AssetSetOverviewTableRow: React.FC<Props> = ({ child, activeTab, deleteBrick, handleTitleChange }) => {
    const brick = useBrick(child.id).brick; // Listens to only to the current active brick.

    if (!brick) return null;

    return (
        <TableRow key={brick.id}>
            <TableCell classes={{ root: 'asset-set-overview-table-row__cell' }}>
                <div className="asset-set-overview-table-row__item__title-box">
                    <Tooltip title={BrickSetupHelper.getSetupBrickTitle(brick)}>
                        <div className="asset-set-overview-table-row__item__title-box__icon" onClick={() => setActiveItem(child.id, activeTab)}>
                            <TypeIcon subType={brick.subType} brickId={brick.id} warnings={[]} errors={[]} />
                        </div>
                    </Tooltip>
                    <TextInlineEdit
                        value={brick.title ?? ''}
                        noEmptyAllowed
                        onChange={(value) => handleTitleChange(value, brick)}
                        useDebounce
                        classes={{ root: 'asset-set-overview-table-row__item__title-box__title' }}
                        textFieldProps={{
                            InputProps: { classes: { root: 'asset-set-overview-table-row__item__title-box__textfield' } }
                        }}
                    />
                </div>
            </TableCell>
            <TableCell classes={{ root: 'asset-set-overview-table-row__cell' }}>
                <AssetWarningMessage brick={brick} classes={{ root: 'asset-set-overview-table-row__asset-warning' }} />
            </TableCell>
            <TableCell classes={{ root: 'asset-set-overview-table-row__cell' }}>
                <IconButton onClick={() => setActiveItem(brick.id, activeTab)}>
                    <Icon>edit</Icon>
                </IconButton>
                <IconButton onClick={() => deleteBrick(brick.id)}>
                    <Icon>delete_outline</Icon>
                </IconButton>
            </TableCell>
        </TableRow>
    );
};

export default AssetSetOverviewTableRow;
