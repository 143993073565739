import { ValidationObject } from 'components/bricks/types/validator.type';

const validators: ValidationObject = {
    validators: [
        {
            condition: '!brick.title || !brick.title.length',
            message: 'Title is required',
            inputIdentifiers: ['cm360_placementGroup-title'],
            tabKey: 'settings',
            severity: 'error'
        },
        {
            condition: '!brick.data?.settings?.advertiserId || !brick.data?.settings?.advertiserId.length',
            message: 'Advertiser id is required for CM360 Campaign',
            inputIdentifiers: ['cm360_placementGroup-advertiser_id'],
            tabKey: 'settings',
            severity: 'error'
        },
        {
            condition: 'brick.data?.settings?.siteType === "site" && (!brick.data?.settings?.siteId || !brick.data?.settings?.siteId.length)',
            message: 'You need to select a site',
            inputIdentifiers: ['cm360_placementGroup-site'],
            tabKey: 'settings',
            severity: 'error'
        },
        {
            condition:
                '!brick.data?.settings?.pricingSchedule || !brick.data?.settings?.pricingSchedule.startDate || !brick.data?.settings?.pricingSchedule.startDate.length',
            message: 'You need to select starting date for pricing schedule',
            inputIdentifiers: ['cm360_placementGroup-pricing_schedule-start_time'],
            tabKey: 'settings',
            severity: 'error'
        },
        {
            condition:
                '!brick.data?.settings?.pricingSchedule || !brick.data?.settings?.pricingSchedule.endDate || !brick.data?.settings?.pricingSchedule.endDate.length',
            message: 'You need to select end date for pricing schedule',
            inputIdentifiers: ['cm360_placementGroup-pricing_schedule-end_time'],
            tabKey: 'settings',
            severity: 'error'
        },
        {
            condition:
                '!brick.data?.settings?.pricingSchedule || !brick.data?.settings?.pricingSchedule.pricingType || !brick.data?.settings?.pricingSchedule.pricingType.length',
            message: 'You need to select pricing type for pricing schedule',
            inputIdentifiers: ['cm360_placementGroup-pricing_schedule-pricing_type'],
            tabKey: 'settings',
            severity: 'error'
        },
        {
            condition:
                'brick.data?.settings?.pricingSchedule && brick.data?.settings?.pricingSchedule?.capCostOption === "CAP_COST_MONTHLY" && !brick.data?.settings?.pricingSchedule?.flighted',
            message: 'Placement must be flighted to cap its cost monthly',
            inputIdentifiers: ['cm360_placementGroup-pricing_schedule-cap_cost_option'],
            tabKey: 'settings',
            severity: 'error'
        },
        {
            condition: '!brick.data?.settings?.tagFormats?.value || !brick.data?.settings?.tagFormats?.value?.length',
            message: 'You must set at least one tag format',
            inputIdentifiers: ['cm360_placement-tag_formats'],
            tabKey: 'settings',
            severity: 'error'
        },
        {
            condition: '!brick.data?.settings?.paymentSource',
            message: 'You must set a payment source',
            inputIdentifiers: ['cm360_placement-tag_formats'],
            tabKey: 'settings',
            severity: 'error'
        },
        {
            condition: '!brick.data?.settings?.sizes || !brick.data?.settings?.sizes.length',
            message: 'You must set at least one dimensions',
            inputIdentifiers: ['cm360_placement-sizes'],
            tabKey: 'settings',
            severity: 'error'
        }
    ]
};

export default validators;
