import React from 'react';
import { CreativeV2Enriched } from 'components/creatives-v2/components/creative-editor/types/creativeV2.type';
import CreativeOverview from 'components/creatives-v2/components/creative-overview';
import './styles/creatives.scss';
import useBrick from 'components/bricks/hooks/useBrick';

const Creatives = () => {
    const { brick, brickChildren } = useBrick();

    if (!brick) return null;

    const getCreatives = () => {
        const creatives: CreativeV2Enriched[] = [];

        brickChildren.forEach((childBrick) => {
            const assetData = childBrick.data?.creatives;
            if (assetData && assetData.length > 0) {
                creatives.push(assetData[0]);
            }
        });

        return creatives;
    };

    return (
        <div className="bricks-asset-set-creatives">
            <CreativeOverview creatives={getCreatives()} availableViews={['canvasFixed', 'canvasFree', 'single']} />
        </div>
    );
};

export default Creatives;
