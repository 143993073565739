import { BrickSetup } from 'components/bricks/types/brick.type';

const setup: BrickSetup = {
    subType: 'email',
    title: 'Email',
    channel: 'email',
    description: 'Create an email.',
    addBrickText: 'Add email',
    outputAction: 'publish',
    availableParentBricks: [],
    availableChildBricks: [],
    defaultTab: 'email',
    tabs: [
        {
            key: 'settings',
            title: 'Settings',
            type: 'multiInput'
        },
        {
            key: 'email',
            title: 'Email',
            type: 'component',
            component: 'preview'
        },
        {
            key: 'briefing',
            title: 'Briefing',
            type: 'component',
            component: 'briefing'
        },
        {
            key: 'publish',
            title: 'Publish',
            type: 'component',
            component: 'output'
        }
    ]
};

export default setup;
